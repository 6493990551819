import styled from "styled-components"

import { Switch } from "@material-ui/core"

import { MText } from "src/ui/MText"
import { MTooltip } from "src/ui/MTooltip/MTooltip"
import { spacing } from "src/ui/spacing"

export function NoiseAlertToggle({
  title,
  state,
  setState,
  children,
  disabled = false,
  titleTooltipComponent,
  toggleTooltipText,
}: {
  title: string
  state: boolean
  setState: (b: boolean) => void
  titleTooltipComponent: React.ReactNode
  disabled?: boolean
  children?: React.ReactNode
  toggleTooltipText?: string
}) {
  const toggle = (
    <Switch
      disabled={disabled}
      checked={state === true}
      onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
        const newVal = Boolean(ev.target.checked)
        setState(newVal)
      }}
    />
  )

  return (
    <ToggleContainer>
      <MText variant="subtitle">
        {title} {titleTooltipComponent}
      </MText>
      {toggleTooltipText && toggleTooltipText.length ? (
        <MTooltip title={toggleTooltipText}>{toggle}</MTooltip>
      ) : (
        toggle
      )}
      {state && children && <OpenToggleContent>{children}</OpenToggleContent>}
    </ToggleContainer>
  )
}

const OpenToggleContent = styled.div`
  grid-column: 1 / span 2;
`

const ToggleContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  gap: ${spacing.M};
`
