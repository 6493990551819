import styled from "styled-components"

import { breakpoint } from "src/constants/breakpoints"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { MBadge } from "src/ui/MBadge/MBadge"
import { MCard } from "src/ui/MCard/MCard"
import { MText } from "src/ui/MText"
import { MTooltip } from "src/ui/MTooltip/MTooltip"
import { spacing } from "src/ui/spacing"

type PresetSettingCardProps = {
  title: React.ReactNode
  description?: React.ReactNode
  labeledPills: (LabelledOnOffPillProps & { id: string })[]
  onManageClick: () => void
  children?: React.ReactNode
}

export function PresetSettingCard({
  title,
  description,
  labeledPills,
  onManageClick,
  children,
}: PresetSettingCardProps) {
  const { t, langKeys } = useTranslate()

  return (
    <MCard boxShadow={false} border padding={spacing.L}>
      <Content>
        <HeaderContent>
          <MText variant="heading3">{title}</MText>
          <MButton variant="subtle" size="small" onClick={onManageClick}>
            {t(langKeys.manage)}
          </MButton>
        </HeaderContent>

        {description && (
          <SubtitleText variant="body" color="secondary">
            {description}
          </SubtitleText>
        )}

        <PillsContent>
          {labeledPills.map(({ id, ...props }) => {
            return <LabelledOnOffPill key={id} {...props} />
          })}
        </PillsContent>
      </Content>

      {children}
    </MCard>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.XS};
  container-type: inline-size;
`

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SubtitleText = styled(MText)`
  padding-top: ${spacing.XS};
  padding-bottom: 0;

  @container (${breakpoint.mediumUp}) {
    padding-top: 0;
  }
`

const PillsContent = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.M};
  flex-wrap: wrap;
`

type LabelledOnOffPillProps = {
  label: string
  isOn: boolean
  value?: string
  tooltipData?: string[]
  hidden?: boolean
}

function LabelledOnOffPill({
  label,
  isOn,
  value,
  tooltipData,
  hidden,
}: LabelledOnOffPillProps) {
  const { t, langKeys } = useTranslate()
  if (hidden) return null

  return (
    <PillContainer>
      <MText variant={isOn ? "subtitle" : "body"}>{label}</MText>
      <MTooltip
        title={
          tooltipData &&
          tooltipData.map((line, index) => <p key={line + index}>{line}</p>)
        }
      >
        <MBadge color={isOn ? "info" : "neutral"}>
          {value ? value : isOn ? t(langKeys.on) : t(langKeys.off)}
        </MBadge>
      </MTooltip>
    </PillContainer>
  )
}

const PillContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS};
  padding-top: ${spacing.XS};
`
