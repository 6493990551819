import { CallAssistUpsellPopupButton } from "src/components/MonitoringPresets/CallAssistUpsellPopupButton"
import { DisclaimerContainer } from "src/components/SettingsProfiles/NoiseAlertSettingCard"
import { usePostTrackNoiseSettingsCallAssistLearnMoreClickedEvent } from "src/data/analytics/queries/settingsAnalyticsQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { Divider } from "src/ui/Divider/Divider"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MText } from "src/ui/MText"

export function NoiseAlertCAUpgradeDisclaimer() {
  const { t, langKeys } = useTranslate()

  const callAssistLearnMoreTrackingEvent =
    usePostTrackNoiseSettingsCallAssistLearnMoreClickedEvent()

  function handleLearnMoreClick() {
    callAssistLearnMoreTrackingEvent.mutate({
      context: "third_alert_card",
    })
  }

  return (
    <DisclaimerContainer>
      <Divider />
      <MText variant="subtitle">
        {t(langKeys.settings_noise_alerts_call_assist_trained_expert)}{" "}
        <CallAssistUpsellPopupButton context="noise_settings" />
      </MText>
      <MText variant="bodyS">
        {t(langKeys.settings_noise_alerts_call_assist_solve)}{" "}
        <ExternalLink
          beforeNav={handleLearnMoreClick}
          href="https://www.minut.com/product/minut-monitoring"
        >
          {t(langKeys.settings_noise_alerts_call_assist_learn_more)}
        </ExternalLink>
      </MText>
    </DisclaimerContainer>
  )
}
