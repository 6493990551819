import styled from "styled-components"

import { MInfo } from "src/ui/Info/MInfo"
import { MText } from "src/ui/MText"
import { Show } from "src/ui/Show/Show"
import { spacing } from "src/ui/spacing"

type PresetSectionProps = {
  hidden?: boolean
  children?: React.ReactNode
  title: React.ReactNode
  titleRight?: React.ReactNode
  description?: React.ReactNode
  infoTooltipContents?: React.ReactNode
}

export function PresetSection({
  hidden,
  title,
  description,
  children,
  infoTooltipContents,
  titleRight,
}: PresetSectionProps) {
  if (hidden) return null

  return (
    <SectionBox>
      <TitleContainer>
        <MText variant="subtitle">
          {title}{" "}
          {infoTooltipContents && <InfoTooltip tooltip={infoTooltipContents} />}
        </MText>

        {titleRight}
      </TitleContainer>

      {description && <MText variant="bodyS">{description}</MText>}

      <Show if={!!children}>
        <SectionContentBox>{children}</SectionContentBox>
      </Show>
    </SectionBox>
  )
}

const SectionBox = styled.div`
  display: grid;
`

const SectionContentBox = styled.div`
  margin-top: ${spacing.L};
  display: grid;
  gap: ${spacing.M};
`

const TitleContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  gap: ${spacing.L} ${spacing.M};
  margin-bottom: ${spacing.XS};
`

function InfoTooltip({ tooltip }: { tooltip: React.ReactNode }) {
  return (
    <MInfo
      iconProps={{ type: "info" }}
      content={tooltip}
      wrapperProps={{ style: { display: "inline" } }}
    />
  )
}
