import { PresetSection } from "src/components/MonitoringPresets/MonitoringPresetDialogs/PresetSection"
import { PresetSectionToggle } from "src/components/MonitoringPresets/MonitoringPresetDialogs/PresetSectionToggle"
import {
  booleanToStatus,
  usePostTrackNoiseSettingsSMSToGuestEditInteractedEvent,
  usePostTrackNoiseSettingsSMSToGuestToggledEvent,
  variantToAlertLevel,
} from "src/data/analytics/queries/settingsAnalyticsQueries"
import { TNoiseAlertVariant } from "src/data/profileSettings/noiseAlertTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MTextArea } from "src/ui/MTextArea/MTextArea"

const MAX_MESSAGE_LENGTH = 2000

export function SectionSms({
  smsEnabled,
  onSetSmsEnabled,
  message,
  setMessage,
  variant,
}: {
  smsEnabled: boolean
  onSetSmsEnabled: (value: boolean) => void
  message: string
  setMessage: (value: string) => void
  variant: TNoiseAlertVariant
}) {
  const { langKeys, t } = useTranslate()
  const smsToGuestChangeTrackingEvent =
    usePostTrackNoiseSettingsSMSToGuestToggledEvent()
  const smsMessageInteractedTrackingEvent =
    usePostTrackNoiseSettingsSMSToGuestEditInteractedEvent()

  function handleSmsEnabled(value: boolean) {
    smsToGuestChangeTrackingEvent.mutate({
      alert_level: variantToAlertLevel[variant],
      new_status: booleanToStatus(value),
    })
    onSetSmsEnabled(value)
  }

  function handleSmsMessageFocus() {
    smsMessageInteractedTrackingEvent.mutate({
      alert_level: variantToAlertLevel[variant],
    })
  }

  return (
    <PresetSectionToggle
      title={t(langKeys.settings_noise_alerts_sms_title)}
      state={smsEnabled}
      setState={handleSmsEnabled}
      infoTooltipContents={t(langKeys.settings_noise_alerts_sms_description)}
    >
      <PresetSection
        title={t(langKeys.settings_noise_alerts_sms_message)}
        description={t(
          langKeys.profiles_noise_monitoring_sms_guest_message_description
        )}
      >
        <MTextArea
          required={smsEnabled}
          maxRows={4}
          maxLength={MAX_MESSAGE_LENGTH}
          value={message}
          onChange={(value) => setMessage(value)}
          onFocus={handleSmsMessageFocus}
        />
      </PresetSection>
    </PresetSectionToggle>
  )
}
