import { PresetSection } from "src/components/MonitoringPresets/MonitoringPresetDialogs/PresetSection"
import { PresetSectionToggle } from "src/components/MonitoringPresets/MonitoringPresetDialogs/PresetSectionToggle"
import {
  booleanToStatus,
  usePostTrackNoiseSettingsAutocallEditInteractedEvent,
  usePostTrackNoiseSettingsAutocallToggledEvent,
  variantToAlertLevel,
} from "src/data/analytics/queries/settingsAnalyticsQueries"
import { TNoiseAlertVariant } from "src/data/profileSettings/noiseAlertTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MTextArea } from "src/ui/MTextArea/MTextArea"

export const MAX_MESSAGE_LENGTH = 2000

export function SectionAutocall({
  autocallEnabled,
  toggleAutocallEnabled,
  autocallMessage,
  onSetAutocallMessage,
  callAssistEnabled,
  signalEditBlocked,
  variant,
}: {
  autocallEnabled: boolean
  toggleAutocallEnabled: (b: boolean) => void
  autocallMessage: string
  onSetAutocallMessage: (s: string) => void
  callAssistEnabled: boolean
  signalEditBlocked: (blocked: { title: string; body: string }) => void
  variant: TNoiseAlertVariant
}) {
  const { langKeys, t } = useTranslate()
  const autoCallInteractedTrackingEvent =
    usePostTrackNoiseSettingsAutocallToggledEvent()
  const autoCallMessageChangeTrackingEvent =
    usePostTrackNoiseSettingsAutocallEditInteractedEvent()

  const autoCallBlocked = callAssistEnabled && variant === "third_alert"

  const toggleTooltipContents = autoCallBlocked
    ? t(langKeys.profiles_cant_turn_on_autocall_body)
    : undefined

  function handleAutoCallChange(value: boolean) {
    if (autoCallBlocked) {
      signalEditBlocked({
        title: t(langKeys.profiles_cant_turn_on_autocall_title),
        body: t(langKeys.profiles_cant_turn_on_autocall_body),
      })
    } else {
      toggleAutocallEnabled(value)
      autoCallInteractedTrackingEvent.mutate({
        alert_level: variantToAlertLevel[variant],
        new_status: booleanToStatus(value),
      })
    }
  }

  function handleAutocallMessageFocus() {
    autoCallMessageChangeTrackingEvent.mutate({
      alert_level: variantToAlertLevel[variant],
    })
  }

  return (
    <PresetSectionToggle
      title={t(langKeys.settings_noise_alerts_autocall_title)}
      infoTooltipContents={t(
        langKeys.settings_noise_alerts_autocall_description
      )}
      disabled={autoCallBlocked}
      toggleTooltipContents={toggleTooltipContents}
      setState={handleAutoCallChange}
      state={autocallEnabled}
    >
      <PresetSection
        title={t(langKeys.settings_noise_alerts_autocall_message)}
        description={t(
          langKeys.profiles_noise_monitoring_sms_guest_message_description
        )}
      >
        <MTextArea
          required={autocallEnabled}
          maxRows={4}
          maxLength={MAX_MESSAGE_LENGTH}
          value={autocallMessage}
          onChange={(value) => onSetAutocallMessage(value)}
          onFocus={handleAutocallMessageFocus}
        />
      </PresetSection>
    </PresetSectionToggle>
  )
}
