import { useMemo, useState } from "react"

function defaultIsEqualFn<T>(a: T, b: T) {
  return a === b
}

export function useIsValueDirty<T>({
  initial,
  isEqualFn = defaultIsEqualFn,
}: {
  initial: T
  isEqualFn?: (a: T, b: T) => boolean
}) {
  const [value, setValue] = useState(initial)

  const isDirty = useMemo(() => {
    return !isEqualFn(initial, value)
  }, [isEqualFn, value, initial])

  return { value, isDirty, setValue }
}
