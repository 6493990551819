import styled from "styled-components"

import { MText } from "src/ui/MText"
import { MTextArea } from "src/ui/MTextArea/MTextArea"
import { spacing } from "src/ui/spacing"

const MAX_MESSAGE_LENGTH = 2000

export function NoiseAlertTextArea({
  title,
  subtitle,
  value,
  setValue,
  required,
  onFocus,
}: {
  title: string
  subtitle: string
  value: string
  required: boolean
  setValue: (value: string) => void
  onFocus: () => void
}): JSX.Element {
  return (
    <TextAreaContent>
      <div>
        <MText variant="subtitle">{title}</MText>
        <MText variant="body">{subtitle}</MText>
      </div>
      <MTextArea
        required={required}
        maxRows={4}
        value={value}
        maxLength={MAX_MESSAGE_LENGTH}
        onChange={(value) => setValue(value)}
        onFocus={onFocus}
      />
    </TextAreaContent>
  )
}

const TextAreaContent = styled.div`
  display: grid;
  gap: ${spacing.M};
`
