import { useState } from "react"
import styled from "styled-components"

import { NoiseAlertCAUpgradeDisclaimer } from "src/components/MonitoringPresets/MonitoringPresetCards/NoiseAlertCAUpgradeDisclaimer"
import {
  NoiseAlertSettingCardDescription,
  NoiseAlertSettingCardTitle,
} from "src/components/MonitoringPresets/MonitoringPresetCards/PresetSettingCardNoiseAlert"
import { NoiseAlertDialog } from "src/components/SettingsProfiles/NoiseAlertDialog"
import { breakpoint } from "src/constants/breakpoints"
import {
  usePostTrackNoiseSettingManagedEvent,
  variantToAlertLevel,
} from "src/data/analytics/queries/settingsAnalyticsQueries"
import { useGetCallAssistActive } from "src/data/callAssist/queries/callAssistQueries"
import { IProfileResponse } from "src/data/homeProfiles/types/homeProfilesTypes"
import { IOrganizationResponse } from "src/data/organizations/types/organizationTypes"
import { selectSettings } from "src/data/profileSettings/noiseAlertSettings"
import { TNoiseAlertVariant } from "src/data/profileSettings/noiseAlertTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { MBadge } from "src/ui/MBadge/MBadge"
import { MCard } from "src/ui/MCard/MCard"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function NoiseAlertSettingCard({
  variant,
  profileResponse,
  organizationResponse,
}: {
  variant: TNoiseAlertVariant
  profileResponse: IProfileResponse
  organizationResponse: IOrganizationResponse
}) {
  const { t, langKeys } = useTranslate()

  const [showDialog, setShowDialog] = useState(false)

  const manageTrackingEvent = usePostTrackNoiseSettingManagedEvent()

  const { callAssistIsActive } = useGetCallAssistActive()

  const settings = selectSettings(
    variant,
    organizationResponse,
    profileResponse
  )

  const showCallAssistUpgrade = variant === "third_alert" && !callAssistIsActive

  const showCallAssistPill = variant === "third_alert" && callAssistIsActive

  function openDialog() {
    setShowDialog(true)
  }

  function handleManageClick() {
    manageTrackingEvent.mutate({
      alert_level: variantToAlertLevel[variant],
    })
    openDialog()
  }

  return (
    <MCard boxShadow={false} border padding={spacing.L}>
      <NoiseAlertDialog
        variant={variant}
        open={showDialog}
        onClose={() => setShowDialog(false)}
        settings={settings}
      />
      <Content>
        <HeaderContent>
          <MText variant="heading3">
            <NoiseAlertSettingCardTitle variant={variant} />
          </MText>
          <MButton variant="subtle" size="small" onClick={handleManageClick}>
            {t(langKeys.manage)}
          </MButton>
        </HeaderContent>

        <SubtitleText variant="body" color="secondary">
          <NoiseAlertSettingCardDescription variant={variant} />
        </SubtitleText>

        <PillsContent>
          <LabelledOnOffPill
            label={t(langKeys.settings_noise_alerts_sms_title)}
            isOn={settings.smsEnabled}
          />
          <LabelledOnOffPill
            label={t(langKeys.settings_noise_alerts_autocall_title)}
            isOn={settings.autocallEnabled}
          />
          <LabelledOnOffPill
            label={t(langKeys.settings_noise_alerts_flash_and_sound_title)}
            isOn={settings.flashEnabled}
          />
          {showCallAssistPill && (
            <LabelledOnOffPill
              label={t(langKeys.call_assist)}
              isOn={settings.callAssistEnabled ?? false}
            />
          )}
        </PillsContent>
        {showCallAssistUpgrade && <NoiseAlertCAUpgradeDisclaimer />}
      </Content>
    </MCard>
  )
}

function LabelledOnOffPill({ label, isOn }: { label: string; isOn: boolean }) {
  const { t, langKeys } = useTranslate()
  return (
    <PillContainer>
      <MText variant={isOn ? "subtitle" : "body"}>{label}</MText>
      <MBadge color={isOn ? "info" : "neutral"}>
        {isOn ? t(langKeys.on) : t(langKeys.off)}
      </MBadge>
    </PillContainer>
  )
}

export const DisclaimerContainer = styled.div`
  padding-top: ${spacing.M};
  display: flex;
  flex-direction: column;
  gap: ${spacing.M};
`

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const PillsContent = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.M};
  flex-wrap: wrap;
`

const PillContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS};
  padding-top: ${spacing.XS};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.XS};
  container-type: inline-size;
`

const SubtitleText = styled(MText)`
  padding-top: ${spacing.XS};
  padding-bottom: 0;

  @container (${breakpoint.mediumUp}) {
    padding-top: 0;
  }
`
