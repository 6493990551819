import { PresetSectionToggle } from "src/components/MonitoringPresets/MonitoringPresetDialogs/PresetSectionToggle"
import {
  booleanToStatus,
  usePostTrackNoiseSettingsFlashAndSoundToggledEvent,
  variantToAlertLevel,
} from "src/data/analytics/queries/settingsAnalyticsQueries"
import { TNoiseAlertVariant } from "src/data/profileSettings/noiseAlertTypes"
import { useTranslate } from "src/i18n/useTranslate"

export function SectionFlashAndSound({
  flashSoundEnabled,
  toggleFlashSoundEnabled,
  variant,
}: {
  flashSoundEnabled: boolean
  toggleFlashSoundEnabled: (b: boolean) => void
  variant: TNoiseAlertVariant
}) {
  const { langKeys, t } = useTranslate()
  const flashAndSoundChangeTrackingEvent =
    usePostTrackNoiseSettingsFlashAndSoundToggledEvent()

  function handleFlashAndSoundChange(value: boolean) {
    flashAndSoundChangeTrackingEvent.mutate({
      alert_level: variantToAlertLevel[variant],
      new_status: booleanToStatus(value),
    })
    toggleFlashSoundEnabled(value)
  }

  return (
    <PresetSectionToggle
      title={t(langKeys.settings_noise_alerts_flash_and_sound_title)}
      infoTooltipContents={t(
        langKeys.settings_noise_alerts_flash_and_sound_description
      )}
      state={flashSoundEnabled}
      setState={handleFlashAndSoundChange}
    ></PresetSectionToggle>
  )
}
