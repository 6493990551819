import { CallAssistUpsellPopupButton } from "src/components/MonitoringPresets/CallAssistUpsellPopupButton"
import { PresetSectionToggle } from "src/components/MonitoringPresets/MonitoringPresetDialogs/PresetSectionToggle"
import {
  booleanToStatus,
  usePostTrackNoiseSettingsCallAssistToggledEvent,
} from "src/data/analytics/queries/settingsAnalyticsQueries"
import { TNoiseAlertVariant } from "src/data/profileSettings/noiseAlertTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"

export function SectionCallAssist({
  setState,
  state,
  autocallEnabled,
  canUseCallAssist,
  signalEditBlocked,
  variant,
}: {
  state: boolean
  setState: (b: boolean) => void
  autocallEnabled: boolean
  canUseCallAssist: boolean
  signalEditBlocked: (blocked: { title: string; body: string }) => void
  variant: TNoiseAlertVariant
}) {
  const { t, langKeys } = useTranslate()
  const { navigate } = useRouter()
  const callAssistToggledEvent =
    usePostTrackNoiseSettingsCallAssistToggledEvent()

  const callAssistBlocked = autocallEnabled && variant === "third_alert"

  const toggleTooltip = callAssistBlocked
    ? t(langKeys.profiles_cant_turn_on_call_assist_body)
    : undefined

  function handleToggle(value: boolean) {
    if (callAssistBlocked) {
      signalEditBlocked({
        title: t(langKeys.profiles_cant_turn_on_call_assist_title),
        body: t(langKeys.profiles_cant_turn_on_call_assist_body),
      })
    } else if (canUseCallAssist) {
      callAssistToggledEvent.mutate({
        alert_level: "third",
        new_status: booleanToStatus(value),
      })
      setState(value)
    } else {
      callAssistToggledEvent.mutate({
        alert_level: "third",
        new_status: "activation",
      })
      navigate(Routes.CallAssistActivate.location())
    }
  }

  return (
    <PresetSectionToggle
      title={
        <>
          {t(langKeys.call_assist)}{" "}
          {!canUseCallAssist && (
            <CallAssistUpsellPopupButton context="noise_settings" />
          )}
        </>
      }
      infoTooltipContents={
        canUseCallAssist &&
        t(langKeys.settings_noise_alerts_call_assist_trained_expert)
      }
      state={state}
      setState={handleToggle}
      toggleTooltipContents={toggleTooltip}
    ></PresetSectionToggle>
  )
}
