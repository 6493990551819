import {
  IProfileRequest,
  IProfileResponse,
} from "src/data/homeProfiles/types/homeProfilesTypes"
import {
  IOrganizationResponse,
  TOrganizationPatch,
} from "src/data/organizations/types/organizationTypes"
import {
  TNoiseAlertSettings,
  TNoiseAlertVariant,
} from "src/data/profileSettings/noiseAlertTypes"

type TGuestCommunicationSetting = Exclude<
  Exclude<
    TOrganizationPatch["guest_communication"],
    undefined
  >["disturbance_monitoring"],
  undefined
>["first_notice" | "second_notice" | "third_notice"]

export function selectSettings(
  variant: TNoiseAlertVariant,
  organizationResponse: IOrganizationResponse,
  profileResponse: IProfileResponse
): TNoiseAlertSettings {
  const ids = {
    profileId: profileResponse.id,
    orgId: organizationResponse.id,
  } as const

  const orgSettings =
    organizationResponse?.guest_communication?.disturbance_monitoring
  const profileSettings = profileResponse?.disturbance_monitoring

  const settings: Record<TNoiseAlertVariant, TNoiseAlertSettings> = {
    first_alert: {
      autocallContent: orgSettings?.first_notice?.autocall_content ?? "",
      smsContent: orgSettings?.first_notice?.sms_content ?? "",
      smsEnabled: profileSettings?.first_notice_sms_enabled ?? false,
      autocallEnabled: profileSettings?.first_notice_autocall_enabled ?? false,
      flashEnabled: profileSettings?.first_notice_flash_and_sound ?? false,
      ...ids,
    },
    second_alert: {
      autocallContent: orgSettings?.second_notice?.autocall_content ?? "",
      smsContent: orgSettings?.second_notice?.sms_content ?? "",
      smsEnabled: profileSettings?.second_notice_sms_enabled ?? false,
      autocallEnabled: profileSettings?.second_notice_autocall_enabled ?? false,
      flashEnabled: profileSettings?.second_notice_flash_and_sound ?? false,
      ...ids,
    },
    third_alert: {
      autocallContent: orgSettings?.third_notice?.autocall_content ?? "",
      smsContent: orgSettings?.third_notice?.sms_content ?? "",
      smsEnabled: profileSettings?.third_notice_sms_enabled ?? false,
      autocallEnabled: profileSettings?.third_notice_autocall_enabled ?? false,
      flashEnabled: profileSettings?.third_notice_flash_and_sound ?? false,
      callAssistEnabled:
        profileResponse.disturbance_monitoring?.call_assist ?? false,
      ...ids,
    },
  }

  return settings[variant]
}

export function createProfileRequest(
  variant: TNoiseAlertVariant,
  smsToGuest: boolean,
  autoCall: boolean,
  flashAndSound: boolean,
  callAssist: boolean
) {
  const keys = {
    first_alert: {
      sms: "first_notice_sms_enabled",
      autoCall: "first_notice_autocall_enabled",
      flashAndSound: "first_notice_flash_and_sound",
    },
    second_alert: {
      sms: "second_notice_sms_enabled",
      autoCall: "second_notice_autocall_enabled",
      flashAndSound: "second_notice_flash_and_sound",
    },
    third_alert: {
      sms: "third_notice_sms_enabled",
      autoCall: "third_notice_autocall_enabled",
      flashAndSound: "third_notice_flash_and_sound",
    },
  } as const
  const disturbance_monitoring: IProfileRequest["disturbance_monitoring"] = {
    [keys[variant].sms]: smsToGuest,
    [keys[variant].autoCall]: autoCall,
    [keys[variant].flashAndSound]: flashAndSound,
  }

  if (variant === "third_alert") {
    disturbance_monitoring.call_assist = callAssist
  }

  return { disturbance_monitoring }
}

export function createOrganizationRequest(
  variant: TNoiseAlertVariant,
  sms: string,
  autocall: string
) {
  const keys = {
    first_alert: "first_notice",
    second_alert: "second_notice",
    third_alert: "third_notice",
  } as const

  const guestComms: TGuestCommunicationSetting = {
    sms_content: sms,
    autocall_content: autocall,
  }

  return {
    guest_communication: {
      disturbance_monitoring: {
        [keys[variant]]: guestComms,
      },
    },
  }
}
