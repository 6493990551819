import { Switch } from "@material-ui/core"

import { PresetSection } from "src/components/MonitoringPresets/MonitoringPresetDialogs/PresetSection"
import { MTooltip } from "src/ui/MTooltip/MTooltip"

export function PresetSectionToggle({
  title,
  description,
  state,
  setState,
  children,
  disabled = false,
  infoTooltipContents,
  toggleTooltipContents,
}: {
  title: React.ReactNode
  description?: string
  state: boolean
  setState: (b: boolean) => void
  disabled?: boolean
  children?: React.ReactNode
  infoTooltipContents?: React.ReactNode
  toggleTooltipContents?: React.ReactNode
}) {
  return (
    <PresetSection
      title={title}
      description={description}
      infoTooltipContents={infoTooltipContents}
      titleRight={
        <MTooltip title={toggleTooltipContents}>
          <Switch
            disabled={disabled}
            checked={state === true}
            onChange={(ev) => setState(ev.target.checked)}
          />
        </MTooltip>
      }
    >
      {state && children}
    </PresetSection>
  )
}
